// The below palette is generated at http://mcg.mbitson.com/ using our main color for amwell now #1296e7
$mat-amwell: (
    50 : #e3f2fc,
    100 : #b8e0f8,
    200 : #89cbf3,
    300 : #59b6ee,
    400 : #36a6eb,
    500 : #1296e7,
    600 : #108ee4,
    700 : #0d83e0,
    800 : #0a79dd,
    900 : #0568d7,
    A100 : #ffffff,
    A200 : #cce1ff,
    A400 : #99c4ff,
    A700 : #80b5ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);