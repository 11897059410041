@import '~@angular/material/theming';

$amwl-typography: mat-typography-config(
    $font-family: 'Open Sans, Arial, sans-serif'
);

// Override typography for all Angular Material, including mat-base-typography and all components.
@include angular-material-typography($amwl-typography);

// By default ng material does not apply typography to non material components
// Ensure that all elements have the default typography
html {
    font-family: mat-font-family($amwl-typography);
}
