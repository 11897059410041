@import '~@angular/material/theming';
@import 'amwell-theming.scss';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

$amwell-app-primary: mat-palette($mat-amwell);
$amwell-app-accent: mat-palette($mat-amwell);

// Create the theme object (a Sass map containing all of the palettes).
$amwell-app-theme: mat-light-theme($amwell-app-primary, $amwell-app-accent);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($amwell-app-theme);